import React, {useEffect, useState} from "react";
import cls from './InputMobile.module.scss';
import {ModalCamera} from "../../../ModalCamera/ModalCamera.jsx";
import fileIcon from '../../../../img/file_button1.png';

export const InputMobile = ({
                                imageType,
                                photoFrontUrl,
                                setPhotoFrontUrl,
                                photoBackUrl,
                                setPhotoBackUrl,
                                openModalError,
                                disable,
                                setOnAllDisabled,
                                ...otherProps
                            }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        if (loading) {
            setOnAllDisabled(true);
        } else {
            setOnAllDisabled(false);
        }
    }, [loading, setOnAllDisabled]);

    const handleWrapperClick = () => {
        if (!isModalOpen) {
            openModal();
        }
    };

    const imageUrl = imageType === 'front' ? photoFrontUrl : photoBackUrl;
    const altText = `Загруженное ${imageType === 'front' ? 'переднее' : 'заднее'} фото`;

    return (
        <div style={{
            pointerEvents: disable ? 'none' : 'auto',
        }}>
        <div onClick={handleWrapperClick} className={cls.Wrapper}>
            <div className={cls.inputWrapper}>
                {imageType === 'front' ? (
                    <img src={fileIcon} alt={'Загрузить фото'} className={cls.imgButton} />
                ) : (
                    <img src={fileIcon} alt={'Загрузить фото'} className={cls.imgButton} />
                )}
                {imageType === 'front' ? (
                    loading && imageType === 'front' && <p className={cls.loading}>Загрузка...</p>
                ) : (
                    loading && imageType !== 'front' && <p className={cls.loading}>Загрузка...</p>
                )}

                {imageType === 'front' ? (
                    !imageUrl && !loading && (<p className={cls.text}>Лицевая сторона паспорта</p>)
                ) : (
                    !imageUrl && !loading && (<p className={cls.text}>Обратная сторона паспорта</p>)
                )}
                {imageUrl && (
                <img
                    alt={altText}
                    src={imageUrl}
                    className={cls.imgPreview}
                />
                )}
            </div>
            {isModalOpen && (
                <ModalCamera
                    openModalError={openModalError}
                    photoFrontUrl={photoFrontUrl}
                    photoBackUrl={photoBackUrl}
                    setPhotoFrontUrl={setPhotoFrontUrl}
                    setPhotoBackUrl={setPhotoBackUrl}
                    setIsModalOpen isOpen={isModalOpen}
                    onClose={closeModal}
                    loading={loading}
                    setLoading={setLoading}
                    imageType={imageType}
                />
            )}
        </div>
        </div>
    )
};
