const Footer = () => {
    const goToForm = () => {
        const section = document.querySelector( 'form' );
        section?.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    return (
        <footer>
            <section>
                <div className="menu">
                    <div className="logo">
                        <img src="./assets/img/white_icon.svg"/>
                    </div>
                    <div className="row">
                        <div className="text">
                            При возникновении вопросов, вы можете обратиться в наш круглосуточный колл-центр по номерам:
                        </div>
                        <div className="number">
                            <a href="tel:5000">5000</a>
                            <div className="description">(звонок с мобильного бесплатный)</div>
                        </div>
                        <div className="number">
                            <a href="tel:+996312333000">0312 333 000</a>
                        </div>
                        <div className="number">
                            <a href="https://wa.me/+996776333000">0776 333 000</a>
                            <div className="description">WhatsApp</div>
                        </div>
                    </div>
                    <div className="row_2">
                        <div className="address">
                            720021, Кыргызская Республика,<br/>
                            г.Бишкек, ул.Шопокова, 101
                        </div>
                        <a href="https://kkb.kg/" className="link">https://kkb.kg/</a>
                        <a href="mailto:info@kkb.kg" className="link">info@kkb.kg</a>
                        <div className="social-links">
                            <a href="https://www.facebook.com/kkb.kg/" target="_blank">
                                <img src="./assets/img/Feature-Icon.svg"/>
                            </a>
                            <a href="https://www.instagram.com/kkb.kg/" target="_blank">
                                <img src="./assets/img/Feature-Icon-1.svg"/>
                            </a>
                            <a href="https://twitter.com/Kyrgyzkommerts" target="_blank">
                                <img src="./assets/img/Feature-Icon-2.svg"/>
                            </a>
                            <a href="https://www.youtube.com/channel/UCfDtrcL86DUmiMBtp7Z0elw" target="_blank">
                                <img src="./assets/img/Feature-Icon-3.svg"/>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="rights">
                    © Kyrgyzkommertsbank 2021 — Все права защищены.<br/>
                    Лицензия НБКР №010, №010/1
                </div>
            </section>
        </footer>
    );
}

export default Footer;
