import cls from './ScannerForm.module.scss';
import {Input} from "../module/Input/Input.jsx";
import React, {useEffect, useState} from "react";
import {InputMobile} from "../module/InputMobile/InputMobile.jsx";
import {Modal} from "../../Modal/Modal.jsx";
import {IMaskInput} from "react-imask";

export const ScannerForm = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [checked, setChecked] = useState(false);
    const [photoFrontUrl, setPhotoFrontUrl] = useState('');
    const [photoBackUrl, setPhotoBackUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [onAllDisabled, setOnAllDisabled] = useState(false);

    const [allFieldsFilled, setAllFieldsFilled] = useState(false);
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const [isModalOpenError, setIsModalOpenError] = useState(false);
    const [isModalOpenSuccess, setIsModalOpenSuccess] = useState(false);
    const openModalError = () => setIsModalOpenError(true);
    const openModalSuccess = () => setIsModalOpenSuccess(true);
    const closeModalError = () => setIsModalOpenError(false);
    const closeModalSuccess = () => setIsModalOpenSuccess(false);



    useEffect(() => {
        if (photoFrontUrl && photoBackUrl && checked && name && phone) {
            setAllFieldsFilled(true);
        } else {
            setAllFieldsFilled(false);
        }
    }, [photoFrontUrl, photoBackUrl, checked, name, phone]);

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        const sendData = {
            "proc_name": "sic.start_sd_proc",
            "proc_params": [
                {
                    "param": {
                        "name": "item_type",
                        "value": "KKB_003"
                    }
                },
                {
                    "param": {
                        "name": "process_name",
                        "value": "P_KKB_003"
                    }
                },
                {
                    "param": {
                        "name": "src_op_id",
                        "value": Date.now()
                    }
                },
                {
                    "param": {
                        "name": "IN_KKB_003_FIZ.P_FIRSTNAME",
                        "value": name
                    }
                },
                {
                    "param": {
                        "name": "IN_KKB_003_PHONE_IB",
                        "value": phone
                    }
                },
                {
                    "param": {
                        "name": "IN_KKB_003_CHANNEL",
                        "value": "L"
                    }
                },
                {
                    "param": {
                        "name": "IN_KKB_003_SCANDOCSFILES",
                        "value": {
                            "list": [
                                {
                                    "list_item": [
                                        {
                                            "list_param": {
                                                "name": "name",
                                                "value": "1.jpg"
                                            }
                                        },
                                        {
                                            "list_param": {
                                                "name": "mime",
                                                "value": "jpg"
                                            }
                                        },
                                        {
                                            "list_param": {
                                                "name": "data",
                                                "value": photoFrontUrl.split(',')[1]
                                            }
                                        }
                                    ]
                                },
                                {
                                    "list_item": [
                                        {
                                            "list_param": {
                                                "name": "name",
                                                "value": "2.jpg"
                                            }
                                        },
                                        {
                                            "list_param": {
                                                "name": "mime",
                                                "value": "jpg"
                                            }
                                        },
                                        {
                                            "list_param": {
                                                "name": "data",
                                                "value": photoBackUrl.split(',')[1]
                                            }
                                        }
                                    ]
                                },
                            ]
                        }
                    }
                }
            ]
        };
        if (allFieldsFilled === false) {
            alert('Пожалуйста, загрузите фотографии и передней, и задней стороны.');
        } else if(!loading) {
            try {
                const url = window.location.pathname === '/travel' || window.location.pathname === '/travel/' ? 'https://admin.kkb.kg/api/save-travel-application' : 'https://admin.kkb.kg/api/save-card-application';
                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json; charset=utf-8'
                    },
                    body: JSON.stringify({
                        phone: phone,
                        full_name: name,
                        front_image: photoFrontUrl,
                        back_image: photoBackUrl
                    })
                }).then(() => {});

                const response = await fetch('/request.php', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json; charset=utf-8'
                    },
                    body: JSON.stringify(sendData)
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const responseData = await response.json();

                if (responseData.desc === 'OK') {
                    openModalSuccess();
                    setLoading(false);
                } else {
                    openModalError();
                    setLoading(false);
                }

                setName('');
                setPhone('');
                setPhotoFrontUrl('');
                setPhotoBackUrl('');
                setChecked(false);
            } catch (error) {
                openModalError();
                setLoading(false);
            }
        }
    };


    const onCheckboxChange = (e) => {
        setChecked(e.target.checked);
    }

    const changeButtonColor = () => {
        if (loading) {
            return '#B0B0B0';
        }
        if (allFieldsFilled) {
            return '#4892FF';
        }
        return '#B0B0B0';
    };


    return (
        <div>
            {isModalOpenError && (<Modal onClose={closeModalError} type={'errorValidation'}/>)}
            {isModalOpenSuccess && (<Modal onClose={closeModalSuccess} type={'success'}/>)}
            <div className={cls.formWrapper}>
                <input value={name} onChange={(e) => setName(e.target.value)} required className={cls.inputName} type="text"
                       id="name" name="name" placeholder='Как к вам обращаться?'/>
                <IMaskInput
                    mask="+{996}(000)000-000"
                    className={cls.inputName}
                    definitions={{'#': /[1-9]/}}
                    placeholder='+996'
                    onAccept={(value) => setPhone(value)}
                    overwrite
                    value={phone}
                />
                <p className={cls.titlePhoto}>Приложите изображение паспорта</p>
                <div className={cls.inputPhotoWrapper}>
                    {!isMobile ?
                        <>
                            <Input
                                imageType="front"
                                photoFrontUrl={photoFrontUrl}
                                setPhotoFrontUrl={setPhotoFrontUrl}
                            />
                            <Input
                                imageType="back"
                                photoBackUrl={photoBackUrl}
                                setPhotoBackUrl={setPhotoBackUrl}
                            />
                        </>
                        :
                        <>
                            <InputMobile
                                imageType="front"
                                disable={onAllDisabled}
                                openModalError={openModalError}
                                photoFrontUrl={photoFrontUrl}
                                setPhotoFrontUrl={setPhotoFrontUrl}
                                setOnAllDisabled={setOnAllDisabled}
                            />
                            <InputMobile
                                disable={onAllDisabled}
                                openModalError={openModalError}
                                imageType="back"
                                photoBackUrl={photoBackUrl}
                                setPhotoBackUrl={setPhotoBackUrl}
                                setOnAllDisabled={setOnAllDisabled}
                            />
                        </>
                    }
                </div>

                <div className={cls.checkboxWrapper}>
                    <input onChange={(e) => onCheckboxChange(e)} required type="checkbox" id="agree" name="agree"/>
                    <label htmlFor="agree">Заполняя форму, я принимаю условия передачи информации</label>
                </div>
                <button style={{background: changeButtonColor()}}
                        disabled={!allFieldsFilled || loading}
                        onClick={handleSubmit} className={cls.button}>
                    {loading ? 'Отправка...' : 'Оформить'}
                </button>
            </div>
        </div>
    )
}
