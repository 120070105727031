import './App.scss'
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {Fragment} from "react";

// Pages
import MainPage from "./pagees/MainPage/MainPage.jsx";
import TravelPage from "./pagees/TravelPage.jsx";
import Footer from "./layout/Footer.jsx";
import Header from "./layout/Header.jsx";

function App() {

    const router = createBrowserRouter([
        {
            path: "/",
            element: <MainPage />,
        },
        {
            path: "/travel",
            element: <TravelPage />,
        },
    ]);

    return (
        <Fragment>
            <Header/>
            <RouterProvider router={router} />
            <Footer/>
        </Fragment>
    )
}

export default App
