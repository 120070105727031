import {ScannerForm} from "../../features/Form";

const MainPage = () => {

    const goToForm = () => {
        const section = document.querySelector( '#form' );
        section?.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };

    return (
        <main>
            <div className="blured_bg">
                <section id="section_1">
                    <div className="offer_wrapper">
                        <div className="offer">Золотая карта для<br/> каждого!</div>
                        <div className="descriptor">Онлайн, бесплатно, с приятными бонусами</div>
                    </div>
                    <div className="cart_image_wrapper">
                        <div className="cart_image">
                            <img src="../assets/img/card_top_2.png" className="card_top" alt="card"/>
                            <img src="../assets/img/card_bottom.png" className="card_bottom" alt="card"/>
                        </div>
                    </div>
                    <div className="benefits">
                        <div className="one_benefit">
                            <div className="one_benefit_icon">
                                <img src="../assets/img/pc.svg"/>
                            </div>
                            <div className="one_benefit_title">
                                Оформите заявку онлайн
                            </div>
                        </div>
                        <div className="one_benefit">
                            <div className="one_benefit_icon">
                                <img src="../assets/img/credit-card%201.svg"/>
                            </div>
                            <div className="one_benefit_title">
                                Получите карту бесплатно
                            </div>
                        </div>
                        <div className="one_benefit">
                            <div className="one_benefit_icon">
                                <img src="../assets/img/pin.svg"/>
                            </div>
                            <div className="one_benefit_title">
                                Доставим бесплатно в любую точку Бишкека и города Ош
                            </div>
                        </div>
                    </div>
                    <button className="blue_btn" data-aos="fade-up" onClick={() => goToForm()}>Оформить карту</button>
                </section>
                <section id="section_2">
                    <div className="title" data-aos="fade-up">Премиальная карта теперь доступна всем</div>
                    <div className="description" data-aos="fade-up">
                        KKB Gold – это особые возможности для всех. Быстрое онлайн-оформление, бесплатный выпуск карты,
                        кэшбэк, удобный сервис
                    </div>
                    <div className="banners">
                        <div className="banner">
                            <div className="banner-text">
                                <div className="banner-text_title">Онлайн</div>
                                <div className="banner-text_description">
                                    Все для вашего удобства: карту KKB Gold можно оформить онлайн. Это удобно и быстро.
                                    Мы бесплатно доставим вашу карту в любую точку Бишкека и города Ош
                                </div>
                            </div>
                        </div>

                        <div className="banner">
                            <div className="banner-text">
                                <div className="banner-text_title">Бесплатно</div>
                                <div className="banner-text_description">
                                    Теперь золотая карта доступна каждому – она бесплатная! А еще, мы не возьмем деньги
                                    за все пять лет обслуживания
                                </div>
                            </div>
                        </div>


                        <div className="banner">
                            <div className="banner-text">
                                <div className="banner-text_title">Удобное обналичивание</div>
                                <div className="banner-text_description">
                                    Обналичивайте деньги в банкоматах любых банков по всему Кыргызстану до 100 000 сом
                                    бесплатно!
                                </div>
                            </div>
                        </div>


                        <div className="banner">
                            <div className="banner-text">
                                <div className="banner-text_title">Копить легко</div>
                                <div className="banner-text_description">
                                    Золотая карта позволяет копить деньги. Мы начислим вам 5% годовых на остатки в сомах
                                    на вашей карте.
                                </div>
                            </div>
                        </div>


                        <div className="banner">
                            <div className="banner-text">
                                <div className="banner-text_title">Кэшбэк</div>
                                <div className="banner-text_description">
                                    Мы начислим вам кэшбэк на карту в размере 1% от суммы любой безналичной оплаты
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
            <div className="s3_wrapper">
                <section id="section_3">
                    <div className="form_header" id="form">
                        <div className="form_header_title" data-aos="fade-up">Оформите бесплатную карту онлайн прямо
                            сейчас!
                        </div>
                        <div className="form_header_description" data-aos="fade-up">Доставка в любую точку Бишкека и
                            города Ош
                        </div>
                    </div>
                    <div className="form_wrapper">
                        <ScannerForm/>
                        <div className="image_wrapper">
                            <img src="../assets/img/illustration.svg"/>
                        </div>
                    </div>
                </section>
            </div>
            <div className="s4_wrapper">
                <section id="section_4">
                    <div className="info">
                        <div className="title"
                             data-aos-easing="ease-in-back"
                             data-aos-delay="200"
                             data-aos-offset="0"
                             data-aos="fade-zoom-in"
                        >
                            Мобильный банкинг без границ.
                        </div>
                        <ul className="points"
                            data-aos-easing="ease-in-back"
                            data-aos-delay="200"
                            data-aos-offset="0"
                            data-aos="fade-zoom-in"
                        >
                            <li>Оплачивайте услуги без
                                комиссий, совершайте переводы с карты на карту любых банков – и всё это в одном
                                приложении.
                                Теперь деньги работают быстро, онлайн и на вас.
                            </li>
                            <li>1. Бесплатно скачайте приложение KKB в App Store или Google Play</li>
                            <li>2. Зарегистрируйтесь по номеру телефона</li>
                            <li>3. Пользуйтесь услугами банка когда и где вам удобно</li>
                        </ul>
                        <div className="app_links">
                            <a data-aos="fade-right"
                               data-aos-delay="500"
                               href="https://apps.apple.com/kg/app/kkb/id1522841048"
                               target="_blank">
                                <img src="../assets/img/app_store.svg"/>
                            </a>
                            <a data-aos="fade-right"
                               data-aos-delay="200"
                               href="https://play.google.com/store/apps/details?id=kg.kkb.app"
                               target="_blank">
                                <img src="../assets/img/play_market.svg"/>
                            </a>
                        </div>
                    </div>
                </section>
                <img className="phones" src="../assets/img/phones-mobile.png"/>
            </div>
            <div className="s4_2_wrapper">
                <img className="phones" src="../assets/img/3.png"/>
                <section id="section_4_2">
                    <div className="info">
                        <div className="title"
                             data-aos-easing="ease-in-back"
                             data-aos-delay="200"
                             data-aos-offset="0"
                             data-aos="fade-zoom-in"
                        >
                            Скидки от партнеров банка
                        </div>

                        <div className="text">
                            В KKB Gold больше всего мы любим две вещи: кэшбэк и скидки от партнёров.
                            Оплачивайте покупки и услуги у наших партнёров картами KKB Gold и получайте скидки до 15%!
                        </div>
                        <ul className="points"
                            data-aos-easing="ease-in-back"
                            data-aos-delay="200"
                            data-aos-offset="0"
                            data-aos="fade-zoom-in"
                        >
                            <li>
                                Получить скидку у наших партнёров очень просто:
                            </li>
                            <li>• Перед получением счёта сообщите кассиру, что вы оплатите счёт картой KKB Gold, и вам сделают приятную скидку.</li>
                            <li>• Ознакомиться со списком партнёров и узнать размер скидок можно на нашем сайте в разделе «Платёжные карты» - «Скидки от партнёров».</li>
                            <li>• Кстати, мы ещё и кэшбэк в размере 1% начислим!</li>
                        </ul>
                    </div>
                </section>
            </div>
            <div className="s5_wrapper">
                <section id="section_5">
                    <div className="title">Тарифы и документы</div>
                    <div className="docs">
                        <div className="doc" data-aos="fade-right" data-aos-delay="600">
                            <div className="doc_title">Тарифы</div>
                            <div className="doc_text">Все цены и тарифы</div>
                            <div className="doc_controls">
                                <a className="doc_button" href="Тарифы.pdf" target="_blank">
                                    <img src="../assets/img/Play.svg"/>
                                </a>
                                <div className="doc_icon">
                                    <img src="../assets/img/coins.svg"/>
                                </div>
                            </div>
                        </div>

                        <div className="doc" data-aos="fade-right" data-aos-delay="400">
                            <div className="doc_title">Договор оферты</div>
                            <div className="doc_text">Договор с банком</div>
                            <div className="doc_controls">
                                <a className="doc_button" href="Оферта%20ККБ.pdf" target="_blank">
                                    <img src="../assets/img/Play.svg"/>
                                </a>
                                <div className="doc_icon">
                                    <img src="../assets/img/briefcase.svg"/>
                                </div>
                            </div>
                        </div>

                        <div className="doc" data-aos="fade-right" data-aos-delay="0">
                            <div className="doc_title">Часто задаваемые вопросы</div>
                            <div className="doc_text">Вопросы и ответы</div>
                            <div className="doc_controls">
                                <a className="doc_button" href="./faq.html">
                                    <img src="../assets/img/Play.svg"/>
                                </a>
                                <div className="doc_icon">
                                    <img src="../assets/img/faq.svg"/>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
        </main>
    );
}

export default MainPage;
