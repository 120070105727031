const Header = () => {

    const goToForm = () => {
        const section = document.querySelector( '#form' );
        section?.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    };
    return (
        <header>
            <section>
                <a className="logo_wrapper" href="https://kkb.kg">
                    <img src="../assets/img/logo.svg" alt="logo"/>
                </a>
                <button className="blue_btn" onClick={() => goToForm()}>Оформить карту</button>
                <div className="whatsapp-button">
                    <a className="whatsapp-button-link" href="https://api.whatsapp.com/send?phone=996776333000" target="_blank">
                        <img src="../assets/img/WhatsApp.svg"/>
                    </a>
                </div>
            </section>
        </header>
    );
}

export default Header;
