import cls from './ModalCamera.module.scss';
import {Camera} from "../Form/module/InputMobile/Camera";

import vision from "react-cloud-vision-api";
import {useEffect, useRef, useState} from "react";
import {Modal} from "../Modal/Modal";
import Resizer from "react-image-file-resizer";
vision.init({ auth: process.env.REACT_APP_GOOGLE_VISION_API_KEY });

const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
};

export const ModalCamera = ({
                          isOpen,
                          onClose,
                          photoFrontUrl,
                          setPhotoFrontUrl,
                          photoBackUrl,
                          setPhotoBackUrl,
                          setLoading,
                          imageType,
                          openModalError ,
                          disable
}) => {
    const [isUsingIOSCamera, setIsUsingIOSCamera] = useState(isIOS());
    const [numberOfCameras, setNumberOfCameras] = useState(0);
    const camera = useRef(null);
    const [activeDeviceId, setActiveDeviceId] = useState(undefined);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [resolution, setResolution] = useState(null);
    const [stream, setStream] = useState(null);


    useEffect(() => {
        (async () => {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = devices.filter((device) => device.kind === 'videoinput');

            const initialDevice = videoDevices.find((device) => device.label.includes("camera2 2, facing back"));
            const targetDevice = videoDevices.find((device) => device.label.includes("camera2 0, facing back"));

            if (initialDevice && targetDevice) {
                setTimeout(() => {
                    setActiveDeviceId(targetDevice.deviceId);
                }, 2000);
            }
        })();
    }, []);

    const toggleCameraType = () => {
        setIsUsingIOSCamera(!isUsingIOSCamera);
    };

    /*IOS CAM*/
    useEffect(() => {
        async function enableStream() {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: {
                        facingMode: "environment",
                        width: {ideal: 2560},
                        height: {ideal: 1440}
                    }
                });
                setStream(stream);
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
            } catch (err) {
                console.error("Error accessing media devices.", err);
            }
        }

        enableStream();

        return () => {
            if (stream) {
                stream.getTracks().forEach(track => track.stop());
            }
        };
    }, []);

    if (!isOpen) {
        return null;
    }

    const base64ToBlob = (base64, mimeType) => {
        const byteString = atob(base64.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeType });
    };
    const handleTextDetection = async (imageBase64) => {
        const req = new vision.Request({
            image: new vision.Image({
                base64: imageBase64.split(',')[1],
            }),
            features: [
                new vision.Feature('TEXT_DETECTION', 10),
            ]
        });

        try {
            setLoading(true);
            const response = await vision.annotate(req);
            const detectedText = response.responses[0].textAnnotations.map(annotation => annotation.description).join(' ');
            if (
                detectedText.includes("КЫРГЫЗ")
                || detectedText.includes("КЫРГЫЗСКАЯ")
                || detectedText.includes("KYRGYZ")
                || detectedText.includes("KYRGYZ REPUBLIC")
                || detectedText.includes("Республикасы")
                || detectedText.includes("Республикасы")
                || detectedText.includes("паспорт")
                || detectedText.includes("Кыргызская")
                || detectedText.includes("Семейное")
                || detectedText.includes("Подпись")
            ) {
                setLoading(false);
                const blob = base64ToBlob(imageBase64, 'image/jpeg');
                Resizer.imageFileResizer(
                    blob,
                    1024,
                    1024,
                    'JPEG',
                    100,
                    0,
                    uri => {
                        if (imageType === 'front') {
                            setPhotoFrontUrl(uri);
                        } else setPhotoBackUrl(uri);
                    },
                    'base64',
                    200,
                    200
                );
            } else {
                openModalError();
                if (imageType === 'front') {
                    setPhotoFrontUrl('');
                } else setPhotoBackUrl('');
                setLoading(false);
            }
        } catch (e) {
            openModalError();
            if (imageType === 'front') {
                setPhotoFrontUrl('');
            } else setPhotoBackUrl('');
            setLoading(false);
        }
    };


    const stopStream = () => {
        if (stream) {
            stream.getTracks().forEach(track => track.stop());
        }
        setStream(null);
        onClose();
    }

    return (
        <>
            <div style={{
                pointerEvents: !stream ? 'none' : 'auto',
            }}>
                <div className={cls.modalOverlay}>
                    <div className={cls.modalContent}>
                        <div>
                            <div className={cls.cameraOverlay}>
                                <div className={`${cls.corner} ${cls.topLeft}`}/>
                                <div className={`${cls.corner} ${cls.topRight}`}/>
                                <div className={`${cls.corner} ${cls.bottomLeft}`}/>
                                <div className={`${cls.corner} ${cls.bottomRight}`}/>
                            </div>

                            <div>
                                Поместите документ в рамку
                            </div>
                            <Camera
                                ref={camera}
                                aspectRatio="cover"
                                facingMode={'environment'}
                                numberOfCamerasCallback={(i) => setNumberOfCameras(i)}
                                videoSourceDeviceId={activeDeviceId}
                                errorMessages={{
                                    noCameraAccessible: 'No camera device accessible. Please connect your camera or try a different browser.',
                                    permissionDenied: 'Permission denied. Please refresh and give camera permission.',
                                    switchCamera:
                                        'It is not possible to switch camera to different one because there is only one video device accessible.',
                                    canvas: 'Canvas is not supported.',
                                }}
                                videoReadyCallback={() => {
                                    console.log('Video feed ready.');
                                }}
                            />
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px',
                                zIndex: 3,
                            }}>
                                <button
                                    type={"button"}
                                    style={{
                                        position: 'relative',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        bottom: '0',
                                        width: '100%',
                                        height: '100%',
                                        background: stream ? "linear-gradient(96.95deg, #0068ff 15.97%, #4d95ff 106.69%)" : "grey",
                                        cursor: stream ? 'pointer' : 'not-allowed',
                                        borderRadius: "12px",
                                        fontSize: "25px",
                                        color: "white",
                                        border: "0",
                                        padding: '10px 0',
                                    }}
                                    onClick={() => {
                                        if (camera.current) {
                                            const photo = camera.current.takePhoto();
                                            if (imageType === 'front') {
                                                setPhotoFrontUrl(photo);
                                            } else {
                                                setPhotoBackUrl(photo);
                                            }
                                            handleTextDetection(photo);
                                            stopStream();
                                        }
                                    }}
                                >
                                    Сделать фото
                                </button>
                                {!isUsingIOSCamera && (
                                    <button
                                        type={"button"}
                                        style={{
                                            position: 'relative',
                                            left: '50%',
                                            transform: 'translateX(-50%)',
                                            bottom: '0',
                                            width: '100%',
                                            height: '100%',
                                            background: "none",
                                            borderRadius: "12px",
                                            fontSize: "20px",
                                            color: "white",
                                            border: "1px solid white",
                                            padding: '10px 0',
                                            cursor: stream ? 'pointer' : 'not-allowed',
                                            opacity: 0.4,
                                        }}
                                        onClick={toggleCameraType}
                                    >
                                        Переключить источник
                                    </button>

                                )}
                                <button
                                    type={"button"}
                                    style={{
                                        position: 'relative',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        bottom: '0',
                                        width: '100%',
                                        height: '100%',
                                        background: "none",
                                        borderRadius: "12px",
                                        fontSize: "20px",
                                        color: "white",
                                        border: "1px solid white",
                                        padding: '10px 0',
                                        cursor: stream ? 'pointer' : 'not-allowed',
                                        opacity: 0.4
                                    }}
                                    onClick={stopStream}
                                >
                                    Отмена
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
