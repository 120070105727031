import React, {useState} from "react";
import cls from './Input.module.scss';
import fileIcon from '../../../../img/file_button.svg';
import vision from "react-cloud-vision-api";
import {Modal} from "../../../Modal/Modal";
import Resizer from "react-image-file-resizer";
export const Input = ({
                          imageType,
                          photoFrontUrl,
                          setPhotoFrontUrl,
                          photoBackUrl,
                          setPhotoBackUrl,
                          ...otherProps
                      }) => {
    const fileInputRef = React.useRef();
    const [loading, setLoading] = React.useState(false);

    const [isModalOpenError, setIsModalOpenError] = useState(false);
    const openModalError = () => setIsModalOpenError(true);
    const closeModalError = () => setIsModalOpenError(false);

    const base64ToBlob = (base64, mimeType) => {
        const byteString = atob(base64.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeType });
    };

    const handleTextDetection = async (imageBase64) => {
        const req = new vision.Request({
            image: new vision.Image({
                base64: imageBase64.split(',')[1],
            }),
            features: [
                new vision.Feature('TEXT_DETECTION', 10),
            ]
        });

        try {
            setLoading(true);
            const response = await vision.annotate(req);
            console.log('Detected text:', response.responses[0].textAnnotations);
            const detectedText = response.responses[0].textAnnotations.map(annotation => annotation.description).join(' ');
            if (
                detectedText.includes("КЫРГЫЗ")
                || detectedText.includes("КЫРГЫЗСКАЯ")
                || detectedText.includes("KYRGYZ")
                || detectedText.includes("KYRGYZ REPUBLIC")
                || detectedText.includes("Республикасы")
                || detectedText.includes("Республикасы")
                || detectedText.includes("паспорт")
                || detectedText.includes("Кыргызская")
                || detectedText.includes("Семейное")
                || detectedText.includes("Подпись")
            ) {
                setLoading(false);
                const blob = base64ToBlob(imageBase64, 'image/jpeg');
                Resizer.imageFileResizer(
                    blob,
                    1024,
                    1024,
                    'JPEG',
                    100,
                    0,
                    uri => {
                        if (imageType === 'front') {
                            setPhotoFrontUrl(uri);
                        } else setPhotoBackUrl(uri);
                    },
                    'base64',
                    200,
                    200
                );
            } else {
                if (imageType === 'front') {
                    setPhotoFrontUrl('');
                } else setPhotoBackUrl('');

                openModalError();
                setLoading(false);
            }
        } catch (e) {
            if (imageType === 'front') {
                setPhotoFrontUrl('');
            } else {
                setPhotoBackUrl('');
            }
            console.error('Error during text detection:', e);
            openModalError();
            setLoading(false);
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const updateFunction = imageType === 'front' ? setPhotoFrontUrl : setPhotoBackUrl;
                updateFunction(e.target.result);
                handleTextDetection(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleWrapperClick = () => {
        fileInputRef.current.click();
    };

    const imageUrl = imageType === 'front' ? photoFrontUrl : photoBackUrl;
    const altText = `Загруженное ${imageType === 'front' ? 'переднее' : 'заднее'} фото`;


    return (
        <>
            {isModalOpenError && (<Modal onClose={closeModalError} type={'errorValidation'} />)}
            <div className={cls.inputWrapper} onClick={handleWrapperClick}>
                {imageType === 'front' ? (
                    <img src={fileIcon} alt={'Загрузить фото'} className={cls.imgButton} />
                ) : (
                    <img src={fileIcon} alt={'Загрузить фото'} className={cls.imgButton} />
                )}
                {imageType === 'front' ? (
                    loading && imageType === 'front' && <p className={cls.loading}>Загрузка...</p>
                ) : (
                    loading && imageType !== 'front' && <p className={cls.loading}>Загрузка...</p>
                )}

                {imageType === 'front' ? (
                    !imageUrl && (<p className={cls.text}>Лицевая сторона паспорта</p>)
                ) : (
                    !imageUrl && (<p className={cls.text}>Обратная сторона паспорта</p>)
                )}
                {imageUrl && (
                    <img
                        src={imageUrl}
                        alt={altText}
                        className={cls.imgPreview}
                    />
                )}
                <input
                    ref={fileInputRef}
                    className={cls.input}
                    type="file"
                    onChange={handleFileChange}
                    style={{display: 'none'}}
                    {...otherProps}
                />
            </div>
        </>
    );
};
