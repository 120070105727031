import cls from './Modal.module.scss';
import error from '../../img/Icon_error.svg';
import sccess from '../../img/Icon_success.svg';

export const Modal = ({onClose, type}) => {

    const closeModal = () => {
        onClose();
        console.log('Modal closed');
    }

    const renderModal = () => {
        switch (type) {
            case 'error':
                return (
                    <div className={cls.Modal}>
                        <img src={error} alt={'Ошибка'} className={cls.errorIcon}/>
                        <h2 className={cls.Title}>Ошибка</h2>
                        <p className={cls.Text}>Что-то пошло не так. Попробуйте еще раз.</p>
                        <button onClick={closeModal} className={cls.button}>Закрыть</button>
                    </div>
                )
            case 'success':
                return (
                    <div className={cls.Modal}>
                        <img src={sccess} alt={'Успешно'} className={cls.errorIcon}/>
                        <h2 className={cls.Title}>Успешно</h2>
                        <p className={cls.Text}>Ваша заявка отправлена. Ожидайте звонка.</p>
                        <button onClick={closeModal} className={cls.button}>Закрыть</button>
                    </div>
                )
            case 'errorValidation':
                return (
                    <div className={cls.Modal}>
                        <img src={error} alt={'Ошибка'} className={cls.errorIcon} />
                        <h2 className={cls.Title}>Нечеткое изображение</h2>
                        <p className={cls.Text}>Качество вашего фото недостаточно для обработки: оно может быть слишком темным или с размытыми буквами.</p>
                        <button onClick={closeModal} className={cls.button}>Закрыть</button>
                    </div>
                )
            default:
                return null;
        }
    }

    return (
        <div className={cls.modalOverlay}>
            <div className={cls.modalContent}>
                {renderModal()}
            </div>
        </div>
    )
}